import React, { useEffect, useState } from "react";
import { RoleRouterProps, withRoleRouterFunc } from "../../../routes";
import { StripedTable } from "../../StripedTable";
import { GiftcardType } from "../../../models/GiftcardType";
import { currentDatabaseRef } from "../../../config/constants";
import * as _ from "lodash";
import { AssetType } from "../GiftcardAssetDropzone";
import { PageState } from "../../PageState";
import { Asset } from "./Asset";
import { PDFTemplate } from "../../../models/pdf_template/pdf_template";
import { getFunctions, httpsCallable } from "firebase/functions";
import { child, get, set } from "firebase/database";
import { Market } from "src/models/MarketModels";
import { Globals } from "src/helpers/globals";
import { Button, Card, DividerMenuItem, DropdownButton, MenuItem } from "src/components/wrappers";
import { ConfirmDeleteButton } from "src/components/ConfirmDeleteButton";
export interface GiftcardConfig {
    baseURL: string
    token: string
    uid: string
    marketTypes: any
}

async function fetchTemplatePromise(accountId: string, type: string): Promise<PDFTemplate> {
    const args: any = {
        action: "giftcard-template-read",
        account: accountId,
        type: type,
        template: "pdf"
    };

    const client = httpsCallable(getFunctions(), "clientApi")
    const response = await client(args)
    const pdfTemplate = PDFTemplate.fromJSON(response.data)
    return pdfTemplate
}

function GiftcardTypeList(props: RoleRouterProps) {
    const [giftcardTypes, setGiftcardTypes] = useState<GiftcardType[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [giftcardConfig, setGiftcardConfig] = useState<GiftcardConfig>()
    const [fontList, setFontList] = useState<string[]>([])
    const [imageList, setImageList] = useState<string[]>([])
    const [pdfTemplatesLoaded, setPDFTemplatesLoaded] = useState<boolean>(false)
    const [imagesInUse, setImagesInUse] = useState<Set<string>>(new Set())
    const [fontsInUse, setFontsInUse] = useState<Set<string>>(new Set())
    const [marketMap, setMarketMap] = useState<MarketToGiftcardMap[]>([])
    const [dirty, setDirty] = useState<boolean>(false)
    const [shouldUpdateMarketMap, setShouldUpdateMarketMap] = useState<boolean>(false)
    const [deletedGiftcardTypes, setDeletedGiftcardTypes] = useState<Set<string>>(new Set())

    const accountId = props.role.account_id
    useEffect(() => {
        (async () => {
            const types = await getGiftcardTypes(accountId)
            const giftcardConfig = await getGiftcardConfig(accountId)
            setGiftcardTypes(types)
            setGiftcardConfig(giftcardConfig)
            if (!_.isNil(giftcardConfig)) {
                const fonts = await getAssetList("font", giftcardConfig)
                const images = await getAssetList("image", giftcardConfig)
                const marketMap = await getMarketToGiftcardMap(giftcardConfig)
                setFontList(fonts)
                setImageList(images)
                setMarketMap(marketMap)
            }
            
            setLoaded(true)

            const _imagesInUse: Set<string> = new Set()
            const _fontsInUse: Set<string> = new Set()
            for (const type of types) {
                try {
                    const template = await fetchTemplatePromise(accountId, type.id)
                    for (const line of template.lines) {
                        if (!_.isNil(line.image)) {
                            _imagesInUse.add(line.image)
                        }
                    } 
                    for (const [name, font] of template.fontMap.entries()) {
                        _fontsInUse.add(font)
                    }
                } catch {
                    // Giftcard type may not have a PDF template
                }
            }
            setFontsInUse(_fontsInUse)
            setImagesInUse(_imagesInUse)
            setPDFTemplatesLoaded(true)
        })();
    }, [props.role.account_id])
    return (
        <PageState loading={!loaded} typeName="" dirty={dirty} submit_action={async () => {
            if (shouldUpdateMarketMap) {
                await updateMarketMap(accountId, marketMap)
                setShouldUpdateMarketMap(false)
            }
            setDirty(false)
        }}>
            <div>
                
                <Card className="my-4">
                    <Card.Header>
                        <h4 style={{ float: "left" }}>Giftcard types </h4>
                        <Button style={{ float: "right" }} onClick={() => {
                            const path = "new"
                            props.router.navigate(path)
                        } }> Add gift card type</Button>
                    </Card.Header>
                    <Card.Body>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: "bold" }}>Identifier</th>
                                <th style={{ fontWeight: "bold" }}>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {giftcardTypes.filter((giftcardType) => {
                                return !deletedGiftcardTypes.has(giftcardType.id)
                            }).map((type: GiftcardType) => {
                                return (
                                    <tr key={type.id} onClick={(() => {
                                        const path = `${type.id}`
                                        props.router.navigate(path)
                                    })}>
                                        <td> { type.id }</td>
                                        <td> { getName(type)}</td>
                                        <td>
                                            { !selectedInMarketMap(marketMap, type) ? 
                                            <ConfirmDeleteButton message="Are you sure you wish to delete this gift card type? The action cannot be reversed." onDelete={ async () => {
                                                if (!_.isNil(giftcardConfig)) {
                                                    let currentDeletedGiftcardTypes = _.cloneDeep(deletedGiftcardTypes)
                                                    currentDeletedGiftcardTypes.add(type.id)
                                                    setDeletedGiftcardTypes(currentDeletedGiftcardTypes)
                                                    await deleteGifcardType(giftcardConfig, type.id)
                                                }
                                                }} 
                                            /> : null}
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </StripedTable>
                    </Card.Body>
                    
                </Card>
                <Card>
                    <Card.Header>
                    <h4>Gift card types per market </h4>
                    </Card.Header>
                    <Card.Body>
                    <p> Setup which gift cards/voucher types you want for each of your market.</p>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: "bold" }}>Market</th>
                                <th style={{ fontWeight: "bold" }}>Gift card</th>
                                <th style={{ fontWeight: "bold" }}>Voucher</th>
                            </tr>
                        </thead>
                        <tbody>
                            {marketMap.map((marketToGiftcard: MarketToGiftcardMap) => {
                                return (
                                    <tr key={marketToGiftcard.market.id} onClick={(() => {})}>
                                        <td> { marketToGiftcard.market.name }</td>
                                        <td> 
                                            <DropdownButton id="dropdown-basic-button" title={getGiftcardTypeName(giftcardTypes, marketToGiftcard.giftcard) ?? "Default (giftcard)"} key="giftcard_dropdown" >
                                            {getMarketToGiftcardItems("giftcard", giftcardTypes, marketToGiftcard, marketMap, (marketMap) => {
                                                setDirty(true)
                                                setShouldUpdateMarketMap(true)
                                                setMarketMap(marketMap)
                                            })
                                                }
                                            </DropdownButton>
                                        </td>
                                        <td> 
                                            <DropdownButton id="dropdown-basic-button" title={getGiftcardTypeName(giftcardTypes, marketToGiftcard.voucher) ?? "Default (voucher)"} key="voucher_drowdown" >
                                            {getMarketToGiftcardItems("voucher", giftcardTypes, marketToGiftcard, marketMap, (marketMap) => {
                                                setDirty(true)
                                                setShouldUpdateMarketMap(true)
                                                setMarketMap(marketMap)
                                            })
                                                }
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </StripedTable>
                    </Card.Body>
                    
                </Card>
                <br></br>
                { !_.isNil(giftcardConfig) ?
                <Card>
                    <Card.Header>
                    <h4>Asset upload </h4>
                    </Card.Header>
                    <Card.Body>
                  
                        <Asset 
                            giftcardConfig={giftcardConfig} 
                            fonts={fontList} 
                            images={imageList} 
                            updatedFonts={(fonts) => setFontList(fonts)} 
                            updatedImages={(images) => setImageList(images)}
                            addedFont={(fontName) => {
                                setFontList(prevFontList => {
                                    return addAsset(prevFontList, fontName);
                                });
                            }}
                            addedImage={(image) => {
                                setImageList(prevImageList => {
                                    return addAsset(prevImageList, image)
                                });
                            }}
                            imagesInUse={imagesInUse}
                            fontsInUse={fontsInUse}
                            ready={pdfTemplatesLoaded}
                        /> 
                       
                    </Card.Body>
                </Card>
                 : null }
                 <br></br>
                 <br></br>
            </div>
        </PageState>
    )
}

function selectedInMarketMap(marketMap: MarketToGiftcardMap[], type: GiftcardType) {
    let selectedInMarketMap = false
    for (const map of marketMap) {
        if ((map.giftcard === type.id || map.voucher === type.id) || (type.id === "giftcard") || (type.id === "voucher")) {
            selectedInMarketMap = true
            break
        }
    }
    return selectedInMarketMap
}

async function updateMarketMap(accountId: string, marketMap: MarketToGiftcardMap[]) {
    const marketTypePath = child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/giftcard_service_config/market_types`);
    const marketTypes = {};
    for (const market of marketMap) {
        let object = {};
        if (!_.isNil(market.giftcard)) {
            object["giftcard"] = market.giftcard;
        }
        if (!_.isNil(market.voucher)) {
            object["voucher"] = market.voucher;
        }
        if (!_.isEmpty(object)) {
            marketTypes[market.market.id] = object;
        }
    }
    await set(marketTypePath, marketTypes);
}

function getName(type: GiftcardType): React.ReactNode {
    let name = type.name
    if (type.id === "giftcard" || type.id == "voucher") {
        name += " (Default)"
    }
    return name
}

function getGiftcardTypeName(giftcardTypes: GiftcardType[], id: string | undefined): string | undefined {
    const giftcardType = giftcardTypes.find((value) => { return value.id === id })
    const hasSameName = giftcardTypes.filter((value) => value.name === giftcardType?.name ?? "").length > 1

    let name = giftcardType?.name
    if (!_.isNil(name) && hasSameName) {
        name += ` (${id})`
    }
    return name ?? id
}

type MenuType = "giftcard" | "voucher"

function getMarketToGiftcardItems(menuType: MenuType, giftcardTypes: GiftcardType[], marketToGiftcard: MarketToGiftcardMap, marketMap: MarketToGiftcardMap[], callback: ((map: MarketToGiftcardMap[]) => void)): React.ReactNode {
    let menuItems: JSX.Element[] = [
        <MenuItem
                key={"default"}
                onClick={() => {
                    let newMarketToGiftcard = marketToGiftcard;
                    if (menuType == "giftcard") {
                        newMarketToGiftcard.giftcard = undefined
                    } else {
                        newMarketToGiftcard.voucher = undefined
                    }
                    let marketToGiftcardArray = _.cloneDeep(marketMap);

                    const index = marketToGiftcardArray.findIndex((value) => { return value.market === newMarketToGiftcard.market; });
                    marketToGiftcardArray[index] = newMarketToGiftcard;
                    callback(marketToGiftcardArray);
                } }
                disabled={ giftcardTypes[0].currency != marketToGiftcard.market.currency }
            >
                {"Default"}
            </MenuItem>,
            <DividerMenuItem key={"divider"}></DividerMenuItem>
    ]

    let giftCardItems = giftcardTypes.map((type: GiftcardType) => {
        return (
            <MenuItem
                key={marketToGiftcard.market.id + type.id}
                onClick={() => {
                    let newMarketToGiftcard = marketToGiftcard;
                    if (menuType == "giftcard") {
                        newMarketToGiftcard.giftcard = type.id
                    } else {
                        newMarketToGiftcard.voucher = type.id
                    }
                    let marketToGiftcardArray = _.cloneDeep(marketMap);

                    const index = marketToGiftcardArray.findIndex((value) => { return value.market === newMarketToGiftcard.market; });
                    marketToGiftcardArray[index] = newMarketToGiftcard;
                    callback(marketToGiftcardArray);
                } }
                disabled={type.currency != marketToGiftcard.market.currency || selectedInMarketMap(marketMap, type)}
            >
                {getGiftcardTypeName(giftcardTypes, type.id)}
            </MenuItem>
        )
    })

    menuItems.push(...giftCardItems)
    return menuItems
}

function addAsset(prevAssets: string[], assetToAdd: string) {
    const updatedAssets = [...prevAssets];
    if (!updatedAssets.includes(assetToAdd)) {
        updatedAssets.push(assetToAdd);
    }
    return updatedAssets;
}

export async function getAssetList(assetType: AssetType, giftcardConfig: GiftcardConfig): Promise<string[]> {
    const assetPath = assetType == "image" ? `/api/v1/asset/image-list` : `/api/v1/asset/font-list`
    const url = giftcardConfig.baseURL + assetPath

    const headers = new Headers()

    headers.append("token", giftcardConfig.token)
    headers.append("uid", giftcardConfig.uid)

    const requestInit: RequestInit = {
        method: "GET",
        headers: headers,
    }

    const request = new Request(url, requestInit)
    const response = await fetch(request)
    const json = await response.json()

    if(!_.isNil(json.file_names)) {
        const names = json.file_names as string[]
        return names
    } else {
        return []
    }
}

export async function deleteGifcardType(giftcardConfig: GiftcardConfig | undefined, gifcardTypeId: string) {
    if (_.isNil(giftcardConfig)) { return }
    const url = giftcardConfig.baseURL + `/api/v1/giftcard-type/${gifcardTypeId}`

    const headers = new Headers()

    headers.append("token", giftcardConfig.token)
    headers.append("uid", giftcardConfig.uid)
    headers.append("Content-Type", "application/json")

    const requestInit: RequestInit = {
        method: "DELETE",
        headers: headers
    }

    const request = new Request(url, requestInit)
    const response = await fetch(request)
}

async function getGiftcardTypes(accountId: string): Promise<GiftcardType[]> {
    const args: any = {
        action: "giftcard-type-list",
        account: accountId
    };

    const client = httpsCallable(getFunctions(), "clientApi");
    const value = await client(args);

    const data = (value.data as any[])
    const giftcardTypes: GiftcardType[] = data.flatMap((type: any) => {
        const value = GiftcardType.fromJSON(type)
        return value ? [value] : []
    })
    return giftcardTypes
}

interface MarketToGiftcardMap {
    market: Market
    voucher: string | undefined
    giftcard: string | undefined
}

async function getMarketToGiftcardMap(serviceConfig: GiftcardConfig): Promise<MarketToGiftcardMap[]> {
    const marketTypes = serviceConfig.marketTypes ?? {}
    const markets = await Globals.shared.getMarkets()
    let marketToGiftcardMap: MarketToGiftcardMap[] = []
    for (const market of markets) {
        const marketConfig = marketTypes[market.id]
        let voucher: string | undefined = undefined
        let giftcard: string | undefined = undefined
        if (!_.isNil(marketConfig)) {
            voucher = marketConfig["voucher"]
            giftcard = marketConfig["giftcard"]
        }
        
        const marketMap: MarketToGiftcardMap = {
            market: market,
            voucher: voucher,
            giftcard: giftcard
        }
        marketToGiftcardMap.push(marketMap)
    }
    return marketToGiftcardMap
}

export async function getGiftcardConfig(accountId: string): Promise<GiftcardConfig | undefined> {
    const snap = await get(child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/giftcard_service_config`))
    if (!snap.exists()) { return undefined }
    const config = snap.val()
    if (!_.isNil(config.token) && !_.isNil(config.uid) && !_.isNil(config.base_url)){
        return {
            token: config.token,
            uid: config.uid,
            baseURL: config.base_url,
            marketTypes: config.market_types
        }
    }
    return undefined
}

export default withRoleRouterFunc(GiftcardTypeList)

