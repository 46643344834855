import _, {
    cloneDeep,
    Dictionary,
    isArray,
    isNil,
    isPlainObject
} from "lodash"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { Market, Tax } from "./MarketModels"
import { MarketAmount } from "./MarketAmount"
import Numeral from "numeral"

require("numeral/locales/da-dk") // register da-dk locale settings

export interface RepoMetadata {
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>
}

function isTaxArray(json: any): boolean {
    if (!isArray(json)) {
        return false
    }
    const array = json as any[]
    for (const obj of array) {
        if ((!obj.rate && obj.rate !== 0) || !obj.type || !obj.name) {
            return false
        }
    }
    return true
}

export class MarketTaxes {
    value: Dictionary<Tax[]> | Tax[] = {}
    constructor(json: any) {
        if (isTaxArray(json)) {
            const array = json as any[]
            this.value = array.map((j: any) => { return new Tax(j) })
        } else if (isPlainObject(json)) {
            const dict: Dictionary<Tax[]> = {}
            for (const key in json) {
                const value = json[key]
                if (isTaxArray(value)) {
                    const array = value as any[]
                    dict[key] = array.map((j: any) => { return new Tax(j) })
                }
            }
            this.value = dict
        }
    }

    json(): any {
        if (isArray(this.value)) {
            return this.value.map(tax => { return tax.json() })
        } else {
            const v = this.value as Dictionary<Tax[]>
            const json: any = {}
            for (const key in v) {
                const val = v[key]
                json[key] = val.map(tax => { return tax.json() })
            }
            return json
        }
    }

    static create(value: Tax[], market: string | null): MarketTaxes {
        const taxes = new MarketTaxes({})
        taxes.value = value
        if (market) {
            taxes.explode([market])
        }
        return taxes
    }

    values(market: string | null): Tax[] | null {
        if (isArray(this.value)) {
            return this.value
        } else {
            if (isNil(market)) {
                return null
            }
            const dict = this.value as Dictionary<Tax[]>
            return dict[market] || null
        }
    }

    // Mutating
    explode(markets: string[]) {
        if (isArray(this.value)) {
            const value = this.value as Tax[]
            const dict: Dictionary<Tax[]> = {}
            for (const key of markets) {
                dict[key] = value
            }
            this.value = dict
        } else {
            // Already exploded

        }
    }

    // Mutating
    implode() {
        if (isArray(this.value)) {
            // Already imploded
            return
        } else {
            const dict = this.value as Dictionary<Tax[]>
            const keys = Object.keys(dict)
            if (keys.length >= 1) {
                this.value = dict[keys[0]]
            } else {
                // If there are no keys in dictionary we
                // have an inconsistency. It will be serialized to null,
                // so it is not a critical error
            }
        }
    }

    setMarkets(markets: string[]) {
        if (markets.length <= 1) {
            this.implode()
        } else {
            this.explode(markets)
        }
    }

    setValues(value: Tax[] | null, market: string | null): MarketTaxes | null {
        const clone = cloneDeep(this)
        if (market === null) {
            clone.implode()
        }

        if (isArray(this.value)) {
            if (value === null) {
                return null
            }
            clone.value = value
        } else {
            // Should not happen, as we just imploded value in case of missing market
            if (market === null) { return null }

            const dict = clone.value as Dictionary<Tax[]>
            if (market === null) {
                clone.implode()
            }
            if (value === null) {
                delete dict[market]
            } else {
                dict[market] = value
            }
            if (Object.keys(dict).length === 0) {
                return null
            }
        }
        return clone
    }

    // Mutating
    removeMarket(market: string, remaining: string[]) {
        if (isArray(this.value)) {
            return
        } else {
            const dict = this.value as Dictionary<Tax[]>
            delete dict[market]
        }
        if (remaining.length <= 1) {
            this.implode()
        }
    }
}

export class Tag {
    name: L10nString
    tag: string

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.tag = json.tag
    }

    json(): any {
        return {
            name: this.name.json(),
            tag: this.tag
        }
    }
}

export class AttributeValue {
    localized?: L10nString
    number?: number
    string?: string
    boolean?: boolean

    constructor(json: any) {
        if (typeof json === "object") {
            this.localized = new L10nString(json.text ?? json)
        } else if (typeof json === "string") {
            this.string = json
        } else if (typeof json === "number") {
            this.number = json
        } else if (typeof json === "boolean") {
            this.boolean = json
        }
    }

    json(): any {
        if (!isNil(this.localized)) {
            return this.localized.json()
        } else if (!isNil(this.string)) {
            return this.string
        } else if (!isNil(this.number)) {
            return this.number
        } else if (!isNil(this.boolean)) {
            return this.boolean
        }
    }

    numberValue(): number {
        if (!isNil(this.number)) {
            return this.number
        } else {
            return Number(this.stringValue())
        }
    }

    booleanValue(): boolean {
        if (!isNil(this.boolean)) {
            return this.boolean
        } else {
            return Boolean(this.stringValue())
        }
    }

    localizedValue(): L10nString {
        if (!isNil(this.localized)) {
            return this.localized
        } else {
            return new L10nString(this.stringValue())
        }
    }

    stringValue(): string {
        if (!isNil(this.string)) {
            return this.string
        } else if (!isNil(this.number)) {
            return `${this.number}`
        } else if (!isNil(this.localized)) {
            const l10n = this.localized
            if (typeof l10n.value === "string") {
                return l10n.value
            } else {
                const dict: Dictionary<string> = l10n.value
                const keys = Object.keys(dict)
                if (keys.length === 0) { return "" }
                keys.sort()
                return dict[keys[0]]
            }
        } else {
            return ""
        }
    }

    localizeTo(language: LanguageCode, attribute: Attribute | undefined) {
        if (isNil(attribute)) { return }
        if (!isNil(attribute.type.text)) {
            if (!this.localized) {
                this.localized = new L10nString(this.stringValue())
                delete this.number
                delete this.string
            }
            this.localized.localizeTo(language)
        }
    }

    removeLocalization(language: LanguageCode) {
        if (this.localized) {
            this.localized.removeLocalization(language)
        }
    }
}

export class AttributeOption {
    id: string
    name: L10nString

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.id = json.id
    }

    json(): any {
        return {
            name: this.name.json(),
            id: this.id
        }
    }
}

export class NumberAttribute {
    suffix: L10nString
    scale: number

    constructor(json: any) {
        this.suffix = new L10nString(json.suffix)
        this.scale = json.scale
    }

    json(): any {
        return {
            suffix: this.suffix.json(),
            scale: this.scale
        }
    }
}

export class TextAttribute {
    text: true

    constructor(json: any) {
        this.text = true
    }

    json(): any {
        return {
            text: this.text
        }
    }
}

export class TextEntryAttribute {
    max_length: number
    must_be_unique: boolean

    constructor(json: any) {
        this.max_length = json.max_length
        this.must_be_unique = json.must_be_unique
    }

    json(): any {
        return {
            max_length: this.max_length,
            must_be_unique: this.must_be_unique
        }
    }
}

export class DateAttribute {
    constructor(json: any) {
    }

    json(): any {
        return {
            date: true
        }
    }
}

export class DateTimeAttribute {
    constructor(json: any) {
    }

    json(): any {
        return {
            date_time: true
        }
    }
}


export enum AttributeTypeKey {
    NUMBER = "number",
    OPTIONS = "options",
    TEXT = "text",
    TEXT_ENTRY = "text_entry",
    DATE = "date",
    DATE_TIME = "date_time"
}

export const allAttributeTypes: AttributeTypeKey[] = [
    AttributeTypeKey.OPTIONS,
    AttributeTypeKey.TEXT,
    AttributeTypeKey.NUMBER,
    AttributeTypeKey.DATE,
    AttributeTypeKey.DATE_TIME,
    AttributeTypeKey.TEXT_ENTRY,
]

export function attributeTypeName(type: AttributeTypeKey): string {
    switch (type) {
        case AttributeTypeKey.NUMBER: return "Number"
        case AttributeTypeKey.OPTIONS: return "Options"
        case AttributeTypeKey.TEXT: return "Text"
        case AttributeTypeKey.TEXT_ENTRY: return "Text entry"
        case AttributeTypeKey.DATE: return "Date"
        case AttributeTypeKey.DATE_TIME: return "Date and time"
    }
}

export class AttributeType {
    options?: Dictionary<AttributeOption>
    number?: NumberAttribute
    text?: TextAttribute
    text_entry?: TextEntryAttribute
    date?: DateAttribute
    date_time?: DateTimeAttribute

    constructor(json: any) {
        if (json.options) {
            const opts: Dictionary<AttributeOption> = {}
            for (const key in json.options) {
                opts[key] = new AttributeOption(json.options[key])
            }
            this.options = opts
        } else if (json.number) {
            const number = new NumberAttribute(json.number)
            this.number = number
        } else if (json.text) {
            const text = new TextAttribute(json.text)
            this.text = text
        } else if (json.text_entry) {
            const text_entry = new TextEntryAttribute(json.text_entry)
            this.text_entry = text_entry
        } else if (json.date) {
            this.date = new DateAttribute(json.date)
        } else if (json.date_time) {
            this.date_time = new DateTimeAttribute(json.date_time)
        }
    }

    json(): any {
        if (this.options) {
            const json: any = {}
            for (const key in this.options) {
                json[key] = this.options[key].json()
            }
            return { options: json }
        } else if (this.number) {
            return { number: this.number.json() }
        } else if (this.text) {
            return { text: this.text.json() }
        } else if (this.text_entry) {
            return { text_entry: this.text_entry.json() }
        } else if (this.date) {
            return { date: this.date.json() }
        } else if (this.date_time) {
            return { date_time: this.date_time.json() }
        }
    }

    typeKey(): AttributeTypeKey {
        if (this.options) {
            return AttributeTypeKey.OPTIONS
        } else if (this.number) {
            return AttributeTypeKey.NUMBER
        } else if (this.text) {
            return AttributeTypeKey.TEXT
        } else if (this.text_entry) {
            return AttributeTypeKey.TEXT_ENTRY
        } else if (this.date) {
            return AttributeTypeKey.DATE
        } else if (this.date_time) {
            return AttributeTypeKey.DATE_TIME
        }
        throw new Error("Invalid attribute")
    }
}

export type BadgePlacement = "top_left" | "top_right" | "bottom_left" | "bottom_right"

export class Badge {
    url: string
    placement: BadgePlacement

    constructor(json: any) {
        this.url = json.url
        this.placement = json.placement
    }

    json(): any {
        return {
            url: this.url,
            placement: this.placement
        }
    }
}

export class Attribute {
    id: string
    name: L10nString
    type: AttributeType
    preserveOnLineItem: boolean
    badges?: _.Dictionary<Badge>

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.id = json.id
        this.type = new AttributeType(json.type)
        this.preserveOnLineItem = json.preserve_on_line_item ?? false
        const badges: _.Dictionary<Badge> = {}
        for (const key in json.badges) {
            const badge = json.badges[key]
            if (badge.url && badge.placement) {
                badges[key] = new Badge(badge)
            }
        }
        if (Object.keys(badges).length > 0) {
            this.badges = badges
        }
    }

    json(): any {
        const value: any = {
            name: this.name.json(),
            id: this.id,
            type: this.type.json()
        }
        if (this.preserveOnLineItem) {
            value["preserve_on_line_item"] = true
        }
        if (!_.isNil(this.badges) && Object.keys(this.badges).length > 0) {
            const badges: _.Dictionary<any> = {}
            for (const key in this.badges) {
                badges[key] = this.badges[key].json()
            }
            value.badges = badges
        }
        return value
    }

    typeKey(): AttributeTypeKey {
        return this.type.typeKey()
    }
}

export class AttributeGroup {
    id: string
    name: L10nString
    initiallyCollapsed: boolean
    attributes: string[]

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.id = json.id
        if (json.initially_collapsed) {
            this.initiallyCollapsed = json.initially_collapsed
        } else {
            this.initiallyCollapsed = false
        }
        if (json.attributes) {
            this.attributes = json.attributes
        } else {
            this.attributes = []
        }
    }

    json(): any {
        const value: any = {
            name: this.name.json(),
            id: this.id
        }
        if (this.initiallyCollapsed) {
            value.initially_collapsed = true
        }
        if (this.attributes.length > 0) {
            value.attributes = this.attributes
        }
        return value
    }
}

export class ProductGroup {
    name: L10nString
    group: string
    norwegianArticleGroupCode?: string

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.group = json.group
        if (!_.isNil(json.norwegian_article_group_code)) {
            this.norwegianArticleGroupCode = json.norwegian_article_group_code
        }
    }

    json(): any {
        const value: any = {
            name: this.name.json(),
            group: this.group
        }
        if (!_.isNil(this.norwegianArticleGroupCode)) {
            value.norwegian_article_group_code = this.norwegianArticleGroupCode
        }
        return value
    }
}

export class DimensionValue {
    id: string
    name: L10nString
    image_url?: string
    color?: string

    constructor(json: any) {
        this.id = json.id
        this.name = new L10nString(json.name)
        this.image_url = json.image_url
        this.color = json.color
    }

    json(): any {
        return {
            id: this.id,
            name: this.name.json(),
            image_url: this.image_url,
            color: this.color
        }
    }

    localizeTo(language: LanguageCode) {
        this.name.localizeTo(language)
    }

    removeLocalization(language: LanguageCode) {
        this.name.removeLocalization(language)
    }
}

export class Dimension {
    id: string
    name: L10nString
    values: DimensionValue[]

    constructor(json: any) {
        this.id = json.id
        this.name = new L10nString(json.name)
        this.values = json.values ? json.values.map((valueJson: any) => { return new DimensionValue(valueJson) }) : []
    }

    json(): any {
        return {
            id: this.id,
            name: this.name.json(),
            values: this.values.map(v => { return v.json() })
        }
    }

    localizeTo(language: LanguageCode) {
        this.name.localizeTo(language)
        const values = this.values ? this.values : []
        values.forEach(value => {
            value.localizeTo(language)
        })
    }

    removeLocalization(language: LanguageCode) {
        this.name.removeLocalization(language)
        const values = this.values ? this.values : []
        values.forEach(value => {
            value.removeLocalization(language)
        })
    }
}

export class Variant {
    id: string
    barcode?: string
    name?: L10nString
    image_url?: string
    retail_price?: MarketAmount
    sale_price?: MarketAmount
    cost_price?: MarketAmount
    dimension_values?: Dictionary<string>
    attributes?: Dictionary<AttributeValue>
    search_words: string[]
    // retail_price_map - to come

    constructor(json: any) {
        this.id = json.id
        this.barcode = json.barcode
        this.name = json.name ? new L10nString(json.name) : undefined
        this.image_url = json.image_url
        this.retail_price = !_.isNil(json.retail_price) ? new MarketAmount(json.retail_price) : undefined
        this.cost_price = !_.isNil(json.cost_price) ? new MarketAmount(json.cost_price) : undefined
        this.sale_price = !_.isNil(json.sale_price) ? new MarketAmount(json.sale_price) : undefined
        this.dimension_values = json.dimension_values
        this.search_words = json.search_words
        this.attributes = json.attributes ? Object.keys(json.attributes).reduce((result, key) => {
            result[key] = new AttributeValue(json.attributes[key])
            return result
        }, {}) : undefined
    }

    json(): any {
        return {
            id: this.id,
            barcode: this.barcode ? this.barcode.trim() : undefined,
            name: this.name ? this.name.json() : undefined,
            image_url: this.image_url,
            retail_price: !_.isNil(this.retail_price) ? this.retail_price.json() : undefined,
            sale_price: !_.isNil(this.sale_price) ? this.sale_price.json() : undefined,
            cost_price: !_.isNil(this.cost_price) ? this.cost_price.json() : undefined,
            dimension_values: this.dimension_values,
            search_words: this.search_words,
            attributes: this.attributes ? Object.keys(this.attributes).reduce((result, key) => {
                result[key] = this.attributes![key].json()
                return result
            }, {}) : undefined
        }
    }

    removeMarket(marketKey: string, remaining: string[]) {
        if (!_.isNil(this.retail_price)) {
            this.retail_price.removeMarket(marketKey, remaining)
        }
        if (!_.isNil(this.cost_price)) {
            this.cost_price.removeMarket(marketKey, remaining)
        }
        if (!_.isNil(this.sale_price)) {
            this.sale_price.removeMarket(marketKey, remaining)
        }
    }

    localizeTo(language: LanguageCode, attributes: Dictionary<Attribute>) {
        if (this.name) {
            this.name.localizeTo(language)
        }
        if (this.attributes) {
            for (const key of Object.keys(this.attributes)) {
                const attribute = this.attributes[key]
                attribute.localizeTo(language, attributes[key])
            }
        }
    }

    removeLocalization(language: LanguageCode) {
        if (this.name) {
            this.name.removeLocalization(language)
        }
        if (this.attributes) {
            for (const key of Object.keys(this.attributes)) {
                const attribute = this.attributes[key]
                attribute.removeLocalization(language)
            }
        }
    }

    setMarkets(allMarkets: string[]) {
        if (!_.isNil(this.retail_price)) {
            this.retail_price.setMarkets(allMarkets)
        }
        if (!_.isNil(this.cost_price)) {
            this.cost_price.setMarkets(allMarkets)
        }
        if (!_.isNil(this.sale_price)) {
            this.sale_price.setMarkets(allMarkets)
        }
    }

    localizedName(preferredLanguage: LanguageCode): string | null {
        if (!this.name) {
            return null
        }
        return this.name.localized(preferredLanguage)
    }

    removeDimensionValues(dimension: Dimension) {
        if (!this.dimension_values) {
            return
        }

        delete this.dimension_values[dimension.id]
    }

    costPriceAmount(market: string | null): number | null {
        if (isNil(this.cost_price)) {
            return null
        }
        return this.cost_price.amount(market)
    }

    retailPriceAmount(market: string | null): number | null {
        if (isNil(this.retail_price)) {
            return null
        }
        return this.retail_price.amount(market)
    }

}

export type Unit = "mass/g" | "mass/kg" | "volume/l" | "volume/dl" | "volume/cl" | "volume/ml" | "volume/m3" | "length/m" | "length/cm" | "length/mm" | "area/m2" | "area/cm2" | "area/mm2" | "energy/kwh"

export class UnitPricing {
    unit: Unit
    retail_price_per_unit?: MarketAmount
    cost_price_per_unit?: MarketAmount
    scale?: number
    multiplicity?: number
    constructor(json: any | null) {
        this.unit = json.unit
        this.retail_price_per_unit = !_.isNil(json.retail_price_per_unit) ? new MarketAmount(json.retail_price_per_unit) : undefined
        this.cost_price_per_unit = !_.isNil(json.cost_price_per_unit) ? new MarketAmount(json.cost_price_per_unit) : undefined
        this.scale = json.scale ?? undefined
        this.multiplicity = json.multiplicity ?? undefined
    }

    json() {
        const value: any = {
            unit: this.unit,
            retail_price_per_unit: this.retail_price_per_unit?.json() ?? 0,
            multiplicity: this.multiplicity ?? 1
        }
        if (!_.isNil(this.cost_price_per_unit)) {
            value.cost_price_per_unit = this.cost_price_per_unit.json()
        }
        if (!_.isNil(this.scale)) {
            value.scale = this.scale
        }
        return value
    }
}

export class Product {
    archived: boolean
    id: string
    barcode?: string
    name?: L10nString
    description?: L10nString
    short_description?: L10nString
    retail_price?: MarketAmount
    sale_price?: MarketAmount
    cost_price?: MarketAmount
    image_url?: string
    tags?: Dictionary<boolean>
    attributes?: Dictionary<AttributeValue>
    dimensions?: Dimension[]
    variants?: Variant[]
    taxes?: MarketTaxes
    product_group?: string
    unit_pricing?: UnitPricing
    search_words?: string[]
    // retail_price_map - to come

    formattedVariantPrice(variant: Variant, market: Market | undefined): string {
        const marketId = market?.id ?? null
        const currency = market?.currency
        Numeral.locale("da-dk")

        const price = variant.retailPriceAmount(marketId) ?? this.retailPriceAmount(marketId)
        if (!_.isNil(price)) {
            return `${Numeral(price).format("0,0.00")} ${currency ?? ""}`
        } else {
            return "-"
        }
    }

    pricingMode(): "regular" | "variable_price" | "unit_price" {
        // Products with variants are regular priced
        if (!_.isNil(this.variants)) {
            return "regular"
        }
        // Products with a retail price are regular priced
        if (!_.isNil(this.retail_price)) {
            return "regular"
        }
        // Otherwise, if they have a unit_pricing, they are unit priced
        if (!_.isNil(this.unit_pricing)) {
            return "unit_price"
        }
        // And otherwise they must be variable priced
        return "variable_price"
    }

    formattedPrice(market: Market | undefined): string {
        const marketId = market?.id ?? null
        const currency = market?.currency
        Numeral.locale("da-dk")

        if (this.variants === undefined || this.variants.length === 0) {
            const price = this.retailPriceAmount(marketId)
            if (!_.isNil(price)) {
                return `${Numeral(price).format("0,0.00")} ${currency ?? ""}`
            } else if (this.unit_pricing) {
                return "Unit price"
            } else {
                return "Variable price"
            }
        } else {
            const { minAmount, maxAmount } = this.getMinMax(this.variants, marketId)
            if (minAmount === maxAmount) {
                if (!_.isNil(minAmount)) {
                    return `${Numeral(minAmount).format("0,0.00")} ${currency ?? ""}`
                } else {
                    return "-"
                }
            } else {
                return `${Numeral(minAmount).format("0,0.00")} - ${Numeral(maxAmount).format("0,0.00")} ${currency ?? ""}`
            }
        }
    }

    constructor(json: any | null) {
        if (json) {
            this.id = json.id
            this.barcode = json.barcode
            this.name = json.name ? new L10nString(json.name) : undefined
            this.short_description = json.short_description ? new L10nString(json.short_description) : undefined
            this.description = json.description ? new L10nString(json.description) : undefined
            this.image_url = json.image_url
            this.retail_price = !_.isNil(json.retail_price) ? new MarketAmount(json.retail_price) : undefined
            this.cost_price = !_.isNil(json.cost_price) ? new MarketAmount(json.cost_price) : undefined
            this.sale_price = !_.isNil(json.sale_price) ? new MarketAmount(json.sale_price) : undefined
            this.tags = json.tags
            this.dimensions = json.dimensions ? json.dimensions.map((d: any) => { return new Dimension(d) }) : undefined
            this.variants = json.variants ? json.variants.map((v: any) => { return new Variant(v) }) : undefined
            this.taxes = json.taxes ? new MarketTaxes(json.taxes) : undefined
            this.product_group = json.product_group
            this.search_words = json.search_words 
            this.attributes = json.attributes ? Object.keys(json.attributes).reduce((result, key) => {
                result[key] = new AttributeValue(json.attributes[key])
                return result
            }, {}) : undefined
            this.archived = json.archived === true
            if (!_.isNil(json.unit_pricing)) {
                this.unit_pricing = new UnitPricing(json.unit_pricing)
            }
        } else {
            this.id = ""
            this.archived = false
        }
    }

    private getMinMax(variants: any[], marketId: string | null): { minAmount: number | undefined; maxAmount: number | undefined } {
        let minAmount: number | undefined
        let maxAmount: number | undefined
        for (const variant of variants) {
            const price = variant.retailPriceAmount(marketId) ?? this.retailPriceAmount(marketId)
            if (!_.isNil(price)) {
                if (_.isNil(minAmount) || minAmount > price) {
                    minAmount = price
                }
                if (_.isNil(maxAmount) || maxAmount < price) {
                    maxAmount = price
                }
            }
        }
        return { minAmount, maxAmount }
    }

    json(): any {
        return {
            id: this.id,
            archived: this.archived,
            barcode: this.barcode ? this.barcode.trim() : undefined,
            name: this.name ? this.name.json() : undefined,
            short_description: this.short_description ? this.short_description.json() : undefined,
            description: this.description ? this.description.json() : undefined,
            retail_price: !_.isNil(this.retail_price) ? this.retail_price.json() : undefined,
            sale_price: !_.isNil(this.sale_price) ? this.sale_price.json() : undefined,
            cost_price: !_.isNil(this.cost_price) ? this.cost_price.json() : undefined,
            image_url: this.image_url ? this.image_url.trim() : undefined,
            tags: this.tags,
            dimensions: this.dimensions ? this.dimensions.map(d => { return d.json() }) : undefined,
            variants: this.variants ? this.variants.map(v => { return v.json() }) : undefined,
            taxes: this.taxes ? this.taxes.json() : undefined,
            product_group: this.product_group,
            search_words: this.search_words,
            attributes: this.attributes ? Object.keys(this.attributes).reduce((result, key) => {
                result[key] = this.attributes![key].json()
                return result
            }, {}) : undefined,
            unit_pricing: this.unit_pricing?.json() ?? undefined
        }
    }

    setMarkets(allMarkets: string[]) {
        if (!_.isNil(this.retail_price)) {
            this.retail_price.setMarkets(allMarkets)
        }
        if (!_.isNil(this.cost_price)) {
            this.cost_price.setMarkets(allMarkets)
        }
        if (!_.isNil(this.sale_price)) {
            this.sale_price.setMarkets(allMarkets)
        }

        if (this.taxes) {
            this.taxes.setMarkets(allMarkets)
        }

        if (this.unit_pricing && !_.isNil(this.unit_pricing?.retail_price_per_unit)) {
            this.unit_pricing.retail_price_per_unit.setMarkets(allMarkets)
        }

        if (this.unit_pricing && !_.isNil(this.unit_pricing?.cost_price_per_unit)) {
            this.unit_pricing.cost_price_per_unit.setMarkets(allMarkets)
        }

        if (this.variants) {
            this.variants.forEach(variant => {
                variant.setMarkets(allMarkets)
            })
        }
    }

    removeMarket(marketKey: string, remaining: string[]) {
        if (!_.isNil(this.retail_price)) {
            this.retail_price.removeMarket(marketKey, remaining)
        }
        if (!_.isNil(this.cost_price)) {
            this.cost_price.removeMarket(marketKey, remaining)
        }
        if (!_.isNil(this.sale_price)) {
            this.sale_price.removeMarket(marketKey, remaining)
        }

        if (this.unit_pricing && !_.isNil(this.unit_pricing?.retail_price_per_unit)) {
            this.unit_pricing.retail_price_per_unit.removeMarket(marketKey, remaining)
        }

        if (this.unit_pricing && !_.isNil(this.unit_pricing?.cost_price_per_unit)) {
            this.unit_pricing.cost_price_per_unit.removeMarket(marketKey, remaining)
        }

        if (this.taxes) {
            this.taxes.removeMarket(marketKey, remaining)
        }

        if (this.variants) {
            this.variants.forEach(variant => {
                variant.removeMarket(marketKey, remaining)
            })
        }
    }

    localizeTo(language: LanguageCode, attributes: Dictionary<Attribute>) {
        if (this.name) {
            this.name.localizeTo(language)
        }
        if (this.short_description) {
            this.short_description.localizeTo(language)
        }
        if (this.description) {
            this.description.localizeTo(language)
        }
        if (this.dimensions) {
            this.dimensions.forEach(dimension => {
                dimension.localizeTo(language)
            })
        }
        if (this.variants) {
            this.variants.forEach(variant => {
                variant.localizeTo(language, attributes)
            })
        }
        if (this.attributes) {
            for (const key of Object.keys(this.attributes)) {
                const attribute = this.attributes[key]
                attribute.localizeTo(language, attributes[key])
            }
        }
    }

    removeLocalization(language: LanguageCode) {
        if (this.name) {
            this.name.removeLocalization(language)
        }
        if (this.short_description) {
            this.short_description.removeLocalization(language)
        }
        if (this.description) {
            this.description.removeLocalization(language)
        }
        if (this.dimensions) {
            this.dimensions.forEach(dimension => {
                dimension.removeLocalization(language)
            })
        }
        if (this.variants) {
            this.variants.forEach(variant => {
                variant.removeLocalization(language)
            })
        }
        if (this.attributes) {
            for (const key of Object.keys(this.attributes)) {
                const attribute = this.attributes[key]
                console.log("REMOVE ATTR", typeof attribute, JSON.stringify(attribute))
                attribute.removeLocalization(language)
            }
        }
    }

    localizedName(preferredLanguage: LanguageCode): string | null {
        if (!this.name) {
            return null
        }
        return this.name.localized(preferredLanguage)
    }

    variant(variantId: string): Variant | null {
        for (const variant of this.variants || []) {
            if (variant.id === variantId) {
                return variant
            }
        }
        return null
    }

    localizedDimensionValues(preferredLanguage: LanguageCode, variant: Variant): string | null {
        if (!this.dimensions || !variant.dimension_values) {
            return null
        }

        const values: Dictionary<string> = variant.dimension_values
        const dimensions: Dimension[] = this.dimensions

        let result = ""
        for (const dimension of dimensions) {
            const valueId = values[dimension.id]
            for (const value of dimension.values) {
                if (value.id === valueId) {
                    result += result.length > 0 ? " · " : ""
                    result += dimension.name.localized(LanguageCode.da) + ": " + value.name.localized(LanguageCode.da)
                }
            }
        }

        return result.length > 0 ? result : null
    }

    costPriceAmount(market: string | null): number | null {
        if (isNil(this.cost_price)) {
            return null
        }
        return this.cost_price.amount(market)
    }

    retailPriceAmount(market: string | null): number | null {
        if (isNil(this.retail_price)) {
            return null
        }
        return this.retail_price.amount(market)
    }
}
