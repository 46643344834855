import * as Auth from "../../../helpers/auth"
import * as React from "react"

import { Modal } from "../../wrappers"

import { FormControl, Label } from "../../wrappers"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Card, Dropdown, Form, Button } from "react-bootstrap"
import { L10nString, LanguageCode } from "../../../helpers/L10n"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute, AttributeOption, AttributeTypeKey } from "../../../models/Product"
import { AttributeSelection, Comparison, DeleteButtonSymbol } from "../DiscountRules/AppliesToSelector"
import { StripedTable } from "../../StripedTable"
import * as _ from "lodash"
import dayjs from "dayjs"
import { ShopSelection } from "./ShopSelection"
import { StockCountFilter } from "../../../models/StockCountFilter"
import { LabelledControl } from "../ProductEdit"
import { L10nFormControl } from "../../L10nFormControl"
import { DateEditor, DateTimeEditor } from "../AttributeSelection"
import * as LocalizedFormat from "dayjs/plugin/localizedFormat"
import { child, get } from "firebase/database"

dayjs.extend(LocalizedFormat as any)

interface StockCountOpenModalProps {
    mode: "stock_count_request" | "stock_count"
    role: Role
    stockLocation: string
    openNew: (name: string, filter: StockCountFilter, shops: Set<string>, dueDate: Date, filterDescription: string[]) => void
    cancel: () => void
}

interface CommaSeperatedAttribute {
    id: string
    values: string[]
}

interface StockCountOpenModalState {
    loaded: boolean
    name: string
    email: string
    showFilters: boolean
    filterDate?: Date
    attributes: Attribute[]
    attributeSelection: _.Dictionary<AttributeSelection[]>
    // commaSeperatedTextEntries: Map<number, CommaSeperatedAttribute>
    includeProductsNotInStock: boolean
    currentlyEditedText?: string
    showCalendar: boolean
    selectedShops: Set<string>
    dueDate: Date
    tappedAttribute?: string
    filterType?: FilterType
}

function comparisonDescription(comp: Comparison): string {
    switch (comp) {
        case "==": return "is"
        case "<": return "is before"
        case ">": return "is after"
        case "<=": return "is before or on"
        case ">=": return "is after or on"
    }
}

export function filterDescription(filter: StockCountFilter, mode: "stock_count" | "stock_count_request", attributesDict?: _.Dictionary<Attribute>) {
    let description: string[] = []
    const filterAttrs = filter.filterAttributes ?? []
    const typeName: string = mode === "stock_count" ? "stock count" : "request"
    const includes: string = mode === "stock_count" ? "will include" : "includes"
    const isIncluded: string = mode === "stock_count" ? "will be included" : "are included"
    const onlyIncludes: string = mode === "stock_count" ? "will only include" : "only includes"
    const isExcluded = mode === "stock_count" ? "will be excluded" : "are excluded"
    if (filter.filterDeletedOnly) {
        return [`This ${typeName} ${onlyIncludes} stock for products that have been deleted`]
    }
    if (filter.filterNegativeStock) {
        return [`This ${typeName} ${onlyIncludes} stock for products that have a negative stock value`]
    }
    if (_.isNil(filter.filterDate) && filterAttrs.length === 0) {
        return [`This ${typeName} ${includes} all products in stock`]
    }
    if (!_.isNil(filter.filterDate)) {
        const dateDescription = dayjs(filter.filterDate).format("YYYY-MM-DD")

        description.push(`Only products, that have NOT been counted since ${dateDescription} ${isIncluded} in the ${typeName}.`)
    }
    const filterAttrDict: _.Dictionary<AttributeSelection[]> = {}
    for (const attr of filterAttrs) {
        if (attr.optionId === "") { continue }
        const existing = filterAttrDict[attr.attributeId] ?? []
        existing.push(attr)
        filterAttrDict[attr.attributeId] = existing
    }

    if (Object.keys(filterAttrDict).length > 0) {
        const attributeDescriptions: string[] = []
        for (const attributeId in filterAttrDict) {
            const attribute = attributesDict?.[attributeId]
            const attributeName = attribute?.name.localized(null) ?? attributeId
            const options = filterAttrDict[attributeId].map(o => {
                let valueName = attribute?.type?.options?.[o.optionId]?.name.localized(null) ?? o.optionId

                if (!_.isNil(attribute?.type?.date_time)) {
                    valueName = dayjs(o.optionId).format("LLL")
                }
                if (!_.isNil(attribute?.type?.date)) {
                    valueName = dayjs(o.optionId).format("LL")
                }

                const compDesc = comparisonDescription(o.comparison ?? "==")
                return `${compDesc} <b>${valueName}</b>`
            })
            if (options.length > 1) {
                attributeDescriptions.push(`the attribute <b>${attributeName}</b> either (` + options.join(" OR ") + ")")
            } else {
                attributeDescriptions.push(`the attribute <b>${attributeName}</b> ${options[0]}`)
            }
        }
        if (filter.excludeFilterAttributes === true) {
            if (attributeDescriptions.length === 1) {
                description.push("Products where " + attributeDescriptions[0] + ` ${isExcluded} in the ${typeName}.`)
            } else {
                description.push("Products where<br/>" + attributeDescriptions.join(" AND <br/>") + `<br/>${isExcluded} in the ${typeName}.`)
            }
        } else {
            if (attributeDescriptions.length === 1) {
                description.push("Only products where " + attributeDescriptions[0] + ` ${isIncluded} in the ${typeName}.`)
            } else {
                description.push("Only products where<br/>" + attributeDescriptions.join(" AND <br/>") + `<br/>${isIncluded} in the ${typeName}.`)
            }
        }

        if (filter.includeProductsWithoutStock === true) {
            description.push("This stock count will be for all products matching the filter - not just the ones that are already have a connected stock value")
        }
    }
    if (description.length === 0) {
        return [`This ${typeName} ${includes} all products in stock`]
    }
    return description
}

type FilterType = "filter_deleted_only" | "negative_stock_only" | "include_attributes" | "exclude_attributes" | "all"

function getTypeName(type: FilterType | undefined): string | undefined {
    switch (type) {
        case "filter_deleted_only": return "Only deleted products"
        case "negative_stock_only": return "Only products with negative stock"
        case "include_attributes": return "Products matching attributes"
        case "exclude_attributes": return "Products NOT matching attributes"
        case "all": return "All products"
    }
    return undefined
}

function isAttributeFilter(filterType: FilterType | undefined): boolean {
    return filterType === "include_attributes" || filterType === "exclude_attributes"
}

export class StockCountOpenModal extends React.Component<StockCountOpenModalProps, StockCountOpenModalState> {

    // Constructor

    attributesObserver = new AttributeObserver(this.props.role.account_id)

    constructor(props: StockCountOpenModalProps) {
        super(props)

        this.state = {
            loaded: false,
            name: "",
            email: "",
            showFilters: false,
            filterDate: undefined,
            filterType: undefined,
            attributes: [],
            attributeSelection: {},
            includeProductsNotInStock: false,
            showCalendar: false,
            selectedShops: new Set(),
            // commaSeperatedTextEntries: new Map(),
            dueDate: dayjs().endOf("day").toDate()
        }

        this.attributesObserver.attributesChangedCallback = () => {
            this.setState({ attributes: this.attributesObserver.attributesArray ?? [] })
        }
    }

    // Methods

    getFilter(): StockCountFilter {
        let filter: StockCountFilter = {
            filterDate: this.state.filterDate,
            filterAttributes: this.filterAttributes()
        }
        if (this.state.includeProductsNotInStock) {
            filter.includeProductsWithoutStock = true
        }

        if (!_.isNil(this.state.filterType)) {
            switch (this.state.filterType) {
                case "filter_deleted_only":
                    filter.filterDeletedOnly = true
                    break
                case "negative_stock_only":
                    filter.filterNegativeStock = true
                    break
                case "exclude_attributes":
                    filter.excludeFilterAttributes = true
                    break
                case "include_attributes":
                    // This is the default if filter attributes are present
                    break
            }
        }
        return filter
    }

    openButtonClicked() {
        const filter = this.getFilter()
        this.props.openNew(this.state.name, filter, this.state.selectedShops, this.state.dueDate, this.filterDescription())
    }

    filterAttributes() {
        const filterAttributes = _.cloneDeep(this.state.attributeSelection)
        const updatedFilterAttributes: AttributeSelection[] = []
        for (const filterAttributeArray of Object.values(filterAttributes)) {
            for (const filterAttribute of filterAttributeArray) {
                const attribute = this.attributesObserver.attributesDict?.[filterAttribute.attributeId]
                if (attribute?.typeKey() === AttributeTypeKey.TEXT) {
                    const values = filterAttribute.optionId.split(",").map((string) => string.trim()).filter((string) => string.length > 0)
                    const seen: Set<string> = new Set()
                    for (const value of values) {                        
                        if (seen.has(value)) {
                            continue
                        }
                        updatedFilterAttributes.push({ attributeId: filterAttribute.attributeId, optionId: value, comparison: "==" })
                        seen.add(value)
                    }
                } else {
                    updatedFilterAttributes.push(filterAttribute)
                }
            }
        }
        return updatedFilterAttributes
    }

    filterDescription() {
        const filter = this.getFilter()
        return filterDescription(filter, this.props.mode, this.attributesObserver.attributesDict)
    }

    openButtonEnabled(): boolean {
        if (this.state.name.length === 0) {
            return false
        }
        if (this.props.mode === "stock_count_request" && this.state.selectedShops.size === 0) {
            return false
        }
        for (const selectionArray of Object.values(this.state.attributeSelection)) {
            for (const selection of selectionArray) {
                if (selection.optionId === "") {
                    return false
                }
            }
        }
        return true
    }

    cancelButtonClicked() {
        this.props.cancel()
    }

    nameChanged(name: string) {
        this.setState({ name: name })
    }

    selectAttribute(attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        const selections = clone[attributeId] ?? []
        // selections.push({ attributeId: attributeId, optionId: "", comparison: "==" })
        clone[attributeId] = selections

        this.setState({ attributeSelection: clone, currentlyEditedText: undefined })
    }

    updateCurrentTextAttribute(attributeId: string, value: string) {
        this.setState({ currentlyEditedText: value })
    }

    // Component

    async componentDidMount() {
        const uid = Auth.userId()
        if (!uid) {
            this.props.cancel()
            return
        }

        const userPath = `v1/accounts/${this.props.role.account_id}/users/${uid}`
        const userSnapshot = await get(child(currentDatabaseRef(), userPath))
        if (!userSnapshot.exists()) {
            this.props.cancel()
            return
        }

        this.attributesObserver.start()

        const userJson = userSnapshot.val()
        this.setState({ email: userJson.email, loaded: true })
    }
    componentWillUnmount(): void {
        this.attributesObserver.stop()
    }

    renderShopSelection() {
        return (
            <LabelledControl label="Select shops">
                <ShopSelection accountId={this.props.role.account_id} shopsSelected={(shops) => { this.setState({ selectedShops: shops }) }} />
            </LabelledControl>
        )
    }

    allComps: Comparison[] = ["==", "<", ">", "<=", ">="]


    renderComparison(attributeId: string) {
        const selectionArray = this.state.attributeSelection[attributeId] ?? []
        let comparison: Comparison | undefined
        if (selectionArray.length > 0) {
            comparison = selectionArray[0].comparison
        }
        return <Dropdown onSelect={(value) => { this.setComparison((value ?? undefined) as any, attributeId) }}>
            <Dropdown.Toggle size="sm" variant="outline-dark">{comparisonDescription(comparison ?? "==")}</Dropdown.Toggle>
            <Dropdown.Menu>
                {this.allComps.map(comp => {
                    return <Dropdown.Item key={comp} eventKey={comp}>{comparisonDescription(comp)}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    }

    renderDateValue(attributeId: string): JSX.Element {
        const selectionArray = this.state.attributeSelection[attributeId] ?? []
        let value = ""
        if (selectionArray.length > 0) {
            value = selectionArray[0].optionId
        }
        return <td width={350}><div className="pb-2">{this.renderComparison(attributeId)}</div>

            <DateEditor show={this.state.tappedAttribute === attributeId} value={value} onChanged={(value) => {
                this.setAttribute(value, attributeId)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: attributeId })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderDateTimeValue(attributeId: string): JSX.Element {
        const selectionArray = this.state.attributeSelection[attributeId] ?? []
        let value = ""
        if (selectionArray.length > 0) {
            value = selectionArray[0].optionId
        }

        return <td width={350}><div className="pb-2">{this.renderComparison(attributeId)}</div>
            <DateTimeEditor show={this.state.tappedAttribute === attributeId} value={value} onChanged={(value) => {
                this.setAttribute(value, attributeId)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: attributeId })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderOptionsValue(options: _.Dictionary<AttributeOption>, selections: AttributeSelection[], attributeId: string): JSX.Element {
        let displayValue = "Select a value"

        const optionNames = selections.map((selection) => {
            const option = options[selection.optionId]
            return option.name.localized(null)
        })

        if (optionNames.length > 0) {
            displayValue = optionNames.join(", ")
            if (displayValue.length > 40) {
                displayValue = displayValue.substring(0, 40) + "..."
            }
        }

        const selectedValues: Set<string> = new Set()
        for (const selection of selections) {
            if (selection.attributeId === attributeId) {
                selectedValues.add(selection.optionId)
            }
        }
        return <td width={350}>
            <Dropdown
                onSelect={(selectedValue: any) => {
                    this.toggleAttribute(selectedValue, attributeId)
                }}>
                <Dropdown.Toggle
                    style={{ color: (selections.length === 0) ? "#888888" : "black", width: "100%" }}
                    variant="outline-dark"
                    id="dropdown-attribute-value"
                >
                    {displayValue}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                    {Object.keys(options).map((optionKey) => {
                        const option = options[optionKey]

                        return <Dropdown.Item key={optionKey} active={selectedValues.has(option.id)} eventKey={optionKey}>{option.name.localized(null)}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </td>
    }

    renderTextValue(attributeId: string): JSX.Element {
        const selectionArray = this.state.attributeSelection[attributeId] ?? []
        let value = ""
        if (selectionArray.length > 0) {
            value = selectionArray[0].optionId
        }
        return <td width={350}>
            <L10nFormControl
                placeholder="Enter one or more values as a comma seperated list"
                l10n={new L10nString(value)}
                type="textarea"
                as="textarea"
                language={null}
                style={{ resize: "vertical", borderColor: "black", borderRadius: "0.375rem" }}
                onLocalizationChanged={l10n => {
                    if (_.isNil(l10n)) {
                        this.setAttribute("", attributeId)
                    } else {
                        this.setAttribute(l10n.localized(null), attributeId)
                    }
                }}
            />
        </td>
    }


    minDueDate = new Date()

    renderDueDate() {
        return (
            <LabelledControl label="Due date">
                <div>
                    <Form.Text>Select the date where the stock count should be completed (by the end of the day)</Form.Text>
                    <Calendar
                        minDate={this.minDueDate}
                        date={this.state.dueDate}
                        onChange={(date) => {
                            this.setState({ dueDate: dayjs(date).endOf("day").toDate() })
                        }}
                    />
                </div>
            </LabelledControl>
        )
    }

    toggleAttribute(value: string, attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        const selections = clone[attributeId] ?? []
        const existing = selections.find(selection => selection.optionId === value)
        if (existing) {
            selections.splice(selections.indexOf(existing), 1)
        } else {
            selections.push({ attributeId: attributeId, optionId: value, comparison: "==" })
        }
        if (selections.length === 0) {
            delete clone[attributeId]
        } else {
            clone[attributeId] = selections
        }
        this.setState({ attributeSelection: clone })
    }

    setAttribute(value: string, attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        const selections = clone[attributeId] ?? []

        if (selections.length === 0) {
            selections.push({ attributeId: attributeId, optionId: value, comparison: "==" })
        } else {
            selections[0].optionId = value
        }

        this.setState({ attributeSelection: clone })
    }

    setComparison(value: Comparison | undefined, attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        const selections = clone[attributeId] ?? []
        if (selections.length === 0) {
            selections.push({ attributeId: attributeId, optionId: "", comparison: value })
        } else {
            selections[0].comparison = value
        }
        this.setState({ attributeSelection: clone })
    }

    removeAttribute(attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        delete clone[attributeId]
        this.setState({ attributeSelection: clone })

        // const commaSeperatedTextEntries = _.cloneDeep(this.state.commaSeperatedTextEntries)
        // commaSeperatedTextEntries.delete(index)
        // this.setState({ commaSeperatedTextEntries: commaSeperatedTextEntries })
    }

    render() {
        return (
            <Modal size="lg" show={true} enforceFocus={false} /* NOTE: without enforceFocus=false, text input in overlays of the modal cannot be focused */>
                <Modal.Header>
                    <Modal.Title>
                        {this.props.mode === "stock_count" ? "New stock count" : "Request a stock count"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageState loading={!this.state.loaded} typeName="new stock count">
                        <div style={{ maxWidth: 625, marginLeft: "auto", marginRight: "auto" }}>
                            <Card className="mb-4">
                                <Card.Body>

                                    {this.props.mode === "stock_count" &&
                                        <><Label variant="info">Info</Label> This may take a few minutes if you have thousands of products and variants</>
                                    }
                                    {this.props.mode === "stock_count" &&
                                        <LabelledControl label="Opened by">
                                            <span className="float-sm-end">{this.state.email}</span>
                                        </LabelledControl>
                                    }
                                    <LabelledControl label="Name">
                                        <FormControl
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            placeholder={this.props.mode === "stock_count" ? "Give this stock count a name" : "Name the stock count request"}
                                            onChange={(event: any) => { this.nameChanged(event.target.value) }}
                                        />
                                    </LabelledControl>
                                    {this.props.mode === "stock_count_request" &&
                                        this.renderShopSelection()
                                    }
                                    {this.props.mode === "stock_count_request" &&
                                        this.renderDueDate()
                                    }
                                    <LabelledControl >
                                        <Button variant="outline-primary" size="sm" onClick={() => { this.setState({ showFilters: !this.state.showFilters }) }}> {this.state.showFilters ? "Hide filters" : "Show filters"}</Button>
                                    </LabelledControl>
                                </Card.Body>
                            </Card>

                            {
                                this.state.showFilters &&
                                <Card className="mb-4"><Card.Body>
                                    <Card.Title>Filters</Card.Title>

                                    <LabelledControl label="Products">

                                        <div>
                                            <Form.Text>Filter products based on their properties</Form.Text>

                                            <Dropdown className="mb-2" onSelect={type => {
                                                let filterType: FilterType | undefined = undefined
                                                if (!_.isNil(type)) {
                                                    switch (type) {
                                                        case "filter_deleted_only":
                                                            filterType = "filter_deleted_only"
                                                            break
                                                        case "negative_stock_only":
                                                            filterType = "negative_stock_only"
                                                            break
                                                        case "include_attributes":
                                                            filterType = "include_attributes"
                                                            break
                                                        case "exclude_attributes":
                                                            filterType = "exclude_attributes"
                                                            break
                                                        case "all":
                                                            filterType = "all"
                                                            break
                                                    }
                                                }
                                                this.setState({ filterType: filterType })
                                            }}>
                                                <Dropdown.Toggle variant="outline-primary" size="sm">{getTypeName(this.state.filterType) ?? "How would you like to filter products?"}</Dropdown.Toggle>
                                                <Dropdown.Menu style={{ overflowY: "scroll", maxHeight: 400 }}>
                                                    {(["include_attributes", "exclude_attributes", "filter_deleted_only", "negative_stock_only", "all"] as FilterType[]).map((type) => {
                                                        return <Dropdown.Item key={type} eventKey={type}>{`${getTypeName(type)}`}</Dropdown.Item>
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            {isAttributeFilter(this.state.filterType) && Object.keys(this.state.attributeSelection).length > 0 &&
                                                <StripedTable>
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(this.state.attributeSelection).map((attributeId) => {
                                                            const attribute = this.attributesObserver.attributesDict?.[attributeId]
                                                            const attributeSelections = this.state.attributeSelection[attributeId]

                                                            let attributeName = attributeId
                                                            if (!_.isNil(attribute)) {
                                                                attributeName = attribute.name.localized(null)
                                                            }
                                                            let type: AttributeTypeKey | undefined = undefined
                                                            if (!_.isNil(attribute)) {
                                                                type = attribute.typeKey()
                                                            }
                                                            if (!_.isNil(attribute) && !_.isNil(type)) {
                                                                let renderedValue: JSX.Element = <br />
                                                                switch (type) {
                                                                    case AttributeTypeKey.NUMBER:
                                                                        break

                                                                    case AttributeTypeKey.OPTIONS:
                                                                        renderedValue = this.renderOptionsValue(attribute.type.options!, attributeSelections, attributeId)
                                                                        break

                                                                    case AttributeTypeKey.TEXT:
                                                                        renderedValue = this.renderTextValue(attributeId)
                                                                        break

                                                                    case AttributeTypeKey.TEXT_ENTRY:
                                                                        break

                                                                    case AttributeTypeKey.DATE:
                                                                        renderedValue = this.renderDateValue(attributeId)
                                                                        break

                                                                    case AttributeTypeKey.DATE_TIME:
                                                                        renderedValue = this.renderDateTimeValue(attributeId)
                                                                        break
                                                                }

                                                                return (
                                                                    <tr key={attributeId}>
                                                                        <td>{attributeName}</td>
                                                                        {renderedValue}
                                                                        <td className="narrow">
                                                                            <Button variant="link" onClick={() => { this.removeAttribute(attributeId) }}><DeleteButtonSymbol /></Button>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            } else {
                                                                return <></>
                                                            }
                                                        })}
                                                    </tbody>
                                                </StripedTable>
                                            }
                                            {isAttributeFilter(this.state.filterType) &&

                                                <Dropdown onSelect={item => {
                                                    if (!_.isNil(item)) {
                                                        this.selectAttribute(item)
                                                    }
                                                }}>
                                                    <Dropdown.Toggle variant="outline-primary" size="sm" disabled={this.state.filterType !== "exclude_attributes" && this.state.filterType !== "include_attributes"}>Select one or more attributes</Dropdown.Toggle>
                                                    <Dropdown.Menu style={{ overflowY: "scroll", maxHeight: 400 }}>
                                                        {(this.attributesObserver.attributesArray ?? []).filter(attribute => {
                                                            return this.state.attributeSelection[attribute.id] === undefined
                                                        }).map(attribute => {
                                                            if (attribute.type.options === undefined &&
                                                                attribute.type.text === undefined &&
                                                                attribute.type.date === undefined &&
                                                                attribute.type.date_time === undefined) {
                                                                return undefined
                                                            }

                                                            return <Dropdown.Item key={attribute.id} eventKey={attribute.id}>{`${attribute.name.localized(LanguageCode.da)}`}</Dropdown.Item>
                                                        })}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }

                                        </div>

                                    </LabelledControl>

                                    {isAttributeFilter(this.state.filterType) &&
                                        <LabelledControl label="Include all products matching filter">
                                            <div>
                                                <Form.Text>By default, only products that have or have had stock registered will be part of a stock count. By checking this option, all of the products matching the attribute filter above will be included in the count.<br /></Form.Text>
                                                <Form.Check // prettier-ignore
                                                    type="checkbox"
                                                    id={`default-checkbox`}
                                                    label={`Include all products`}
                                                    disabled={!isAttributeFilter(this.state.filterType) || Object.keys(this.state.attributeSelection).length === 0}
                                                    checked={this.state.includeProductsNotInStock}
                                                    onChange={(val) => { this.setState({ includeProductsNotInStock: val.target.checked, showCalendar: false }) }}
                                                />
                                            </div>
                                        </LabelledControl>
                                    }
                                    {(isAttributeFilter(this.state.filterType) || this.state.filterType === "all") &&

                                        <LabelledControl label="Uncounted">
                                            <div>
                                                <Form.Text>Only count products that have not been counted since selected date<br /></Form.Text>
                                                {!this.state.showCalendar && <Button variant="outline-primary" size="sm" onClick={() => { this.setState({ showCalendar: true }) }}>Select date</Button>}
                                                {this.state.showCalendar && <Calendar
                                                    date={this.state.filterDate}
                                                    maxDate={new Date()}
                                                    minDate={dayjs().subtract(18, "months").toDate()}
                                                    onChange={(date) => {
                                                        if (this.state.filterDate?.getTime() === date.getTime()) {
                                                            this.setState({ filterDate: undefined })
                                                        } else {
                                                            this.setState({ filterDate: date })
                                                        }
                                                    }}
                                                />
                                                }

                                            </div>
                                        </LabelledControl>
                                    }

                                </Card.Body></Card>
                            }
                            <LabelledControl label="Description">
                                <Form.Text>{this.filterDescription().map((line, index) => { return <p dangerouslySetInnerHTML={{ __html: line }} key={index} /> })}</Form.Text>
                            </LabelledControl>
                        </div>
                    </PageState>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={() => { this.cancelButtonClicked() }}>Cancel</Button>
                    <Button size="sm" onClick={() => { this.openButtonClicked() }} disabled={!this.openButtonEnabled()}>{this.props.mode === "stock_count" ? "Open" : "Create"} </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
