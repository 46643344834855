import React, { useEffect, useState } from "react"
import { StockCountRequest, stockCountRequestFromJSON } from "../../../models/StockCountRequest"
import dayjs from "dayjs"
import { Attribute } from "../../../models/Product"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { firestore, currentDatabaseRef } from "../../../config/constants"
import { FirestorePager } from "../../FirestorePager"
import { StockCountRequestStatusModal } from "./StockCountRequestStatusModal"
import { FormattedTime } from "react-intl"
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore"
import { child, get } from "firebase/database"
import { Button } from "src/components/wrappers"
import { getFunctions, httpsCallable } from "firebase/functions"

interface StockCountRequestListProps {
    accountId: string
}

interface RequestWithId {
    request: StockCountRequest
    id: string
}

interface Item {
    id: string
    name: string
}

function requestCollectionRef(accountId: string) {
    return collection(firestore, `accounts/${accountId}/stock_count_requests`)
}

export function stockCountRequestStatus(status: "completed" | "cancelled" | "in_progress" | "created" | "completed_with_cancellations") {
    switch (status) {
        case "cancelled":
            return "Cancelled"
        case "completed":
            return "Completed"
        case "completed_with_cancellations":
            return "Completed (some counts were cancelled)"
        case "created":
            return "Created"
        case "in_progress":
            return "In progress"
    }
}

export function StockCountRequestList(props: StockCountRequestListProps) {
    const thisMorning = dayjs().startOf("day").toDate()

    const [shownRequest, setShownRequest] = useState<RequestWithId | undefined>()
    const [attributes, setAttributes] = useState<_.Dictionary<Attribute> | undefined>()
    const [shops, setShops] = useState<Item[]>([])
    const [cancellationInProgress, setCancellationInProgress] = useState<string | undefined>()

    const attributesObserver = new AttributeObserver(props.accountId)

    useEffect(() => {
        const fetchShops = async () => {
            const shopsRef = child(currentDatabaseRef(), `v1/accounts/${props.accountId}/shop_index`)
            const shopsSnap = await get(shopsRef)
            const shops = shopsSnap.val() ?? {}
            const activeShops: Item[] = []
            for (const key in shops) {
                const shop = shops[key]
                if (shop.deactivated === true) { continue }
                activeShops.push({ id: key, name: shop.name })
            }

            setShops(activeShops)
        }

        attributesObserver.attributesChangedCallback = () => {
            setAttributes(attributesObserver.attributesDict)
        }

        attributesObserver.start()
        fetchShops()

        return () => {
            attributesObserver.stop()
        }
    }, [props.accountId])

    const renderHeader = (showCancel: boolean) => {
        return <tr>
            <th>Created</th>
            <th>Due</th>
            <th>Name</th>
            <th>Status</th>
            { showCancel ? <th>Cancel</th> : null}
            
        </tr>

    }
    const renderRequest = (requestWithId: RequestWithId, showOverdue: boolean, showCancel: boolean) => {
        const request = requestWithId.request
        return <tr key={request.id} style={{backgroundColor: 'gray'}} onClick={() => {
            setShownRequest(requestWithId)
        }}>
            <td width="20%">
                <FormattedTime
                    value={request.creationDate}
                    day="numeric"
                    month="long"
                    year="numeric"
                    hour="numeric"
                    minute="numeric"
                />
            </td>
            <td width="20%">
                <span style={{ color: (showOverdue && request.dueDate < thisMorning) ? "#FF0000" : "black" }}>
                    <FormattedTime
                        value={request.dueDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </span>
            </td>
            <td width="40%">
                {request.name}
            </td>
            <td width="20%">
                {cancellationInProgress === requestWithId.id ? "Cancelling..." : stockCountRequestStatus(request.status)}
            </td>
            { showCancel ? <td width="20%">
                <Button disabled={cancellationInProgress === requestWithId.id} onClick={async (event) => {
                    event.stopPropagation()
                    setCancellationInProgress(requestWithId.id)
                    await cancelStockCountRequest(requestWithId)
                    setCancellationInProgress(undefined)
                }}>Cancel</Button>
            </td> : null}
        </tr>
    }

    async function cancelStockCountRequest(request: RequestWithId) {
        const message = "Are you sure you want to cancel the stock count request?"
        if (window.confirm(message)) {
            const requestId = request.request.id
            const cancelRequest = httpsCallable(getFunctions(), "StockCount-client")
            const args: any = {
                account_id: props.accountId,
                action: "cancel-request",
                request_id: requestId
            }
            await cancelRequest(args)
        }
    }

    return <span>
        {shownRequest && <StockCountRequestStatusModal shops={shops} accountId={props.accountId} attributes={attributes} request={shownRequest.request} requestId={shownRequest.id} onComplete={() => { setShownRequest(undefined) }} />}
        <FirestorePager
            title="Stock count requests"
            renderHeader={() => renderHeader(true)}
            renderElement={(request: RequestWithId) => { return renderRequest(request, true, true) }}
            transform={(document) => { return { request: stockCountRequestFromJSON(document.data() ?? {}), id: document.id } }}
            query={query(requestCollectionRef(props.accountId), orderBy("due_date", "asc"), where("status", "in", ["in_progress", "created"]))}
            limit={10}
        />

        <FirestorePager
            title="Completed stock count requests"
            renderHeader={() => renderHeader(false)}
            renderElement={(request: RequestWithId) => { return renderRequest(request, false, false) }}
            transform={(document) => { return { request: stockCountRequestFromJSON(document.data() ?? {}), id: document.id } }}
            query={query(requestCollectionRef(props.accountId), orderBy("creation_date", "desc"), where("status", "in", ["completed", "cancelled", "completed_with_cancellations"]))}
            limit={10}
        />

    </span>
}

